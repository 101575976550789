import React, { useState, useEffect } from 'react';
import moment from 'moment';


function Home() {

  const [ totalInvested, setTotalInvested] = useState(0);
  const [ currentValue, setCurrentValue] = useState(0);
  const [ change, setChange] = useState(0);
  const [ changePercetage, setChangePercetage] = useState(0);
  const [ news, setNews] = useState([]);
  const [ assets, setAssets] = useState([]);
    
  const numberFormat = (value,currency='AUD') =>
    new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currency
    }).format(value);    
    
  const newsContent = news.map((post) =>
    <div class="pt-5">
      <a href={post.url} target="_blank"><div class="rounded overflow-hidden bg-gray-100 p-5 rounded-lg text-black min-h-[50%]" key={post.id}>
        {post.image  && <img src={post.image} class="float-left object-cover min-h-[50%] min-w-[50%]" />}
        <div class="text-black font-bold text-s text-right">{post.title}</div>
        <div class="text-black font-bold text-xs italic text-right">{moment(post.news_date).format('MMMM Do YYYY')}</div>
      </div></a>
    </div>
  );
    
  const assetContent = assets.map((asset) =>
    <div class="flex space-y-4">
      <div class="pt-5 rounded overflow-hidden bg-gray-100 p-5 min-w-[100%]">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <div class="text-black font-bold text-s text-left">{asset.quantity} x {asset.description} - {asset.year}</div>
            <div class="text-black font-bold text-xs italic text-left">Added to fund on : {moment(asset.added_on).format('MMMM Do YYYY')}</div>        
          </div>
          <div class=""> 
            <div class="text-black font-bold text-s text-right">{asset.changepercent}%</div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    fetch(process.env.REACT_APP_BASEURL + process.env.REACT_APP_PRICEURL +  process.env.REACT_APP_FUNDID)
        .then((response) => response.json())
        .then((data) => {
           console.log(data.news);
           setTotalInvested(data.totalInvested);
           setCurrentValue(data.currentValue);
           setChange(data.change);
           setChangePercetage(data.changePercetage);
           setNews(data.news);
           setAssets(data.assets);
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);


  return (
    <div
      class={`h-full w-full flex items-center justify-center bg-gray-300 flex-col max-w-screen-md m-auto`}
    >
      <div class="overflow-hidden bg-white p-5 text-black ">
        <img
          class="w-1/2 mx-auto"
          src="./logo.png"
          alt="OENO"
        />

        <div class="rounded overflow-hidden bg-black p-5 rounded-lg mt-4 text-black min-h-[50%]">
        
        
          <div class="text-white font-bold text-2xl mb-2 text-right">OENO Australian Fund</div>
          <div class="text-white font-bold text-xl text-right">{changePercetage}% </div>
        
        </div>

        <div class="pt-5">
          <div class="text-gray-800 dark:text-gray-200 font-bold text-l text-left">
            Fund Assets
          </div>
          {assetContent}
        </div>


        <div class="pt-5">
          <div class="text-gray-800 dark:text-gray-200 font-bold text-l text-left">
            Latest News 
          </div>
          {newsContent}
        </div>
      </div>
    </div>
  )
}
export default Home